import AlertModal from '../components/AlertModal.vue'

const Modal = {
  install(Vue, options) {
    this.EventBus = new Vue()
    Vue.component('app-modal', AlertModal)
    Vue.prototype.$modal = {
      show(params) {
        Modal.EventBus.$emit('show', params)
      },
    }
  },
}
export default Modal
