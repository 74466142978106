<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20">
    <path
      fill="#b3bdc2"
      d="M17.127 20H2.781A2.761 2.761 0 0 1 0 17.266V3.157A2.761 2.761 0 0 1 2.781.422h6.977a.646.646 0 0 1 .65.64.646.646 0 0 1-.65.64H2.781A1.469 1.469 0 0 0 1.3 3.157v14.109a1.469 1.469 0 0 0 1.48 1.455h14.347a1.469 1.469 0 0 0 1.48-1.455V11.5a.651.651 0 0 1 1.3 0v5.767A2.761 2.761 0 0 1 17.127 20zM8.41 13.687a1.629 1.629 0 0 1-1.194-.521 1.57 1.57 0 0 1-.416-1.218l.23-2.362a3 3 0 0 1 .89-1.847L15.54.453A1.657 1.657 0 0 1 16.7 0a1.674 1.674 0 0 1 1.172.46l2.644 2.6a1.61 1.61 0 0 1 0 2.3l-7.6 7.268a3.077 3.077 0 0 1-1.93.869l-2.449.186c-.037.003-.083.004-.127.004zM16.7 1.262a.35.35 0 0 0-.248.1L8.833 8.649a1.732 1.732 0 0 0-.5 1.06l-.233 2.36a.3.3 0 0 0 .08.237.309.309 0 0 0 .231.1h.026l2.449-.185a1.775 1.775 0 0 0 1.115-.5l7.6-7.268a.327.327 0 0 0 .1-.236.346.346 0 0 0-.1-.249l-2.644-2.6a.352.352 0 0 0-.257-.106z"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
