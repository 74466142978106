<template>
  <div>
    <v-overlay v-overlay :value="loadingScreen" opacity="1">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Loader',
  computed: {
    ...mapState(['loadingScreen']),
  },
}
</script>
<style lang="scss" scoped></style>
