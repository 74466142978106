import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tokenId: '', //Uc34e92fd0e377dec2afceda1838bd071
    loading: false,
    loadingScreen: false,
    storeId: null,
    storeName: null,
    storeType: 'OUTLET',
    cart: {
      products: null,
    },
    SO: null,
    shopList: [],
  },
  getters: {
    getCart: state => state.cart,
  },
  mutations: {
    getToken(state, payload) {
      state.tokenId = payload
    },
    loading(state, payload) {
      state.loading = payload
    },
    loadingScreen(state, payload) {
      state.loadingScreen = payload
    },
    setStoreId(state, payload) {
      state.storeId = payload
    },
    setStoreName(state, payload) {
      state.storeName = payload
    },
    setStoreType(state, payload) {
      if (payload) state.storeType = payload
    },
    setCart(state, payload) {
      state.cart = payload
    },
    setShopList(state, payload) {
      state.shopList = payload
    },
    changeQty(state, payload) {
      const { index, qty } = payload
      state.cart.products[index].qty = qty
      state.cart.products[index].subtotal =
        state.cart.products[index].qty * state.cart.products[index].unit_price
      state.cart.grand_total = state.cart.products
        .map(item => item.subtotal)
        .reduce((acc, value) => acc + value, 0)
    },
    increment(state, payload) {
      state.cart.products[payload].qty++
      state.cart.products[payload].subtotal =
        state.cart.products[payload].qty * state.cart.products[payload].unit_price
      state.cart.grand_total = state.cart.products
        .map(item => item.subtotal)
        .reduce((acc, value) => acc + value, 0)
    },
    decrement(state, payload) {
      state.cart.products[payload].qty--
      state.cart.products[payload].subtotal =
        state.cart.products[payload].qty * state.cart.products[payload].unit_price
      state.cart.grand_total = state.cart.products
        .map(item => item.subtotal)
        .reduce((acc, value) => acc + value, 0)
    },
    setSO(state, payload) {
      state.SO = payload
    },
  },
  actions: {
    getCart({ state }) {
      const self = this._vm
      return new Promise((resolve, reject) => {
        self
          .$axios({
            method: 'GET',
            url: '/api/cart/view',
            params: { social_id: state.tokenId, store_id: state.storeId },
          })
          .then(res => resolve(res.data))
          .catch(e => reject(e))
      })
    },
    putLogger(_, payload) {
      const self = this._vm
      return new Promise((resolve, reject) => {
        self
          .$axios({
            method: 'POST',
            url: '/api/cart/replace_log',
            data: payload,
          })
          .then(res => resolve(res.data))
          .catch(e => reject(e))
      })
    },
  },
  modules: {},
})
