<template>
  <div>
    <v-app-bar app dark>
      <!-- <v-app-bar-nav-icon @click.stop="click()" /> -->
      <v-icon large @click="goBack" v-if="!$route.meta.ishideBack">
        keyboard_arrow_left
      </v-icon>
      <v-text-field
        ref="input"
        v-if="$route.meta.isSearch"
        single-line
        placeholder="Search..."
        v-model="searchText"
        filled
        rounded
        dense
        hide-details
        class="px-5"
        append-outer-icon="search"
        @click:append-outer="getListShop"
      ></v-text-field>
      <v-toolbar-title v-else>{{
        $route.meta.toolbartitle || `ร้านค้า ${storeName}`
      }}</v-toolbar-title>
    </v-app-bar>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'Topbar',
  data: () => {
    return {
      searchText: '',
    }
  },
  methods: {
    ...mapMutations(['setShopList']),
    async getListShop() {
      try {
        const res = await this.$axios({
          method: 'GET',
          url: '/api/store/list',
          params: {
            social_id: this.$store.state.tokenId,
            store_name: this.searchText,
          },
        })
        if (res.data.status === 'failed') {
          this.$modal.show({
            title: `ERROR: ${res.data.message}`,
            isOne: true,
          })
        }
        this.setShopList(res.data)
      } catch (e) {
        this.$modal.show({
          title: `ERROR: ${e}`,
          isOne: true,
        })
      }
    },
    click() {
      this.$emit('opensidebar')
    },
    goBack() {
      const self = this
      self.$router.go(-1)
    },
  },
  computed: {
    ...mapState(['storeName']),
  },
  mounted() {
    const self = this
    const path = self.$router.currentRoute.fullPath
    window.onpopstate = function(event) {
      if (path === '/listshop') self.searchText = ''
      // alert('location: ' + document.location + ', state: ' + JSON.stringify(event.state))
    }
  },
}
</script>

<style lang="scss" scoped>
.v-toolbar {
  ::v-deep {
    .v-input__append-outer {
      background: rgba(255, 255, 255, 0.08);
      padding: 5px 5px;
      border-radius: 50%;
      margin: 3px 0 0 10px !important;
    }
  }
}
.v-toolbar__title {
  margin: auto;
}
</style>
