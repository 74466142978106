import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import font from './assets/font/font.css'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2'
import dayjs from 'dayjs'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import Modal from './plugins/modal'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.config.productionTip = false
Vue.prototype.$liff = window.liff
Vue.use(font)
Vue.use(VueSweetalert2)
Vue.use(Datetime)
Vue.use(Modal)
Vue.prototype.$dayjs = dayjs
Vue.prototype.$axios = axios
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common.Authorization = process.env.VUE_APP_OAUTH_TOKEN
Vue.prototype.$loading = payload => {
  return store.commit('loading', payload)
}
Vue.prototype.$loadingScreen = payload => {
  return store.commit('loadingScreen', payload)
}
Vue.prototype.$loadingModal = (status, text) => {
  return Vue.swal.fire({
    icon: status,
    title: status === 'success' ? 'Success' : 'Oops...!',
    text: status === 'success' ? text || '' : text || 'Something went wrong',
  })
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
