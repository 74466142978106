<template>
  <v-app id="app">
    <router-view name="topbar" @opensidebar="opensidebar"></router-view>
    <!-- <router-view name="sidebar" ref="sidebar"></router-view> -->
    <main role="main">
      <router-view />
    </main>
    <Loader />
    <!-- <AlertModal/> -->
    <app-modal />
  </v-app>
</template>

<script>
import './assets/style.scss'
import Loader from './components/Loader'
export default {
  name: 'App',
  components: {
    Loader,
  },
  methods: {
    opensidebar() {
      this.$refs.sidebar.drawer = true
    },
    async liffinit() {
      try {
        await this.$liff.init({ liffId: process.env.VUE_APP_LIFF_ID })
      } catch (e) {
        this.$modal.show({
          title: `Error init liff: ${e?.message}`,
          isOne: true,
        })
      }
    },
    async getProfile() {
      try {
        const profile = await this.$liff.getProfile()
        const tokenId = profile.userId
        this.$store.commit('getToken', tokenId)
        await this.checkAction(tokenId)
      } catch (e) {
        this.$modal.show({
          title: `Error get profile: ${e?.message}`,
          isOne: true,
        })
      }
    },
    async checkAction(tokenId) {
      try {
        const res = await this.$axios({
          method: 'GET',
          url: `/api/account/view/${tokenId}`,
        })
        if (res.data.status !== 'failed') {
          if (this.$route.query.action === 'home') {
            this.$router.replace('/listshop')
          } else if (this.$route.query.action === 'cart') {
            this.$router.replace('/cart')
          } else if (this.$route.query.action === 'order') {
            this.$router.replace('/orderstatus')
          }
        } else {
          this.$router.replace('/register')
        }
      } catch (e) {
        this.$modal.show({
          title: `ERROR: ${e}`,
          isOne: true,
        })
      } finally {
        this.$loadingScreen(false)
      }
    },
  },
  mounted() {
    this.$loadingScreen(true)
    this.liffinit()
    this.$liff.ready.then(() => {
      this.getProfile()
    })
  },
}
</script>
<style lang="scss">
.row {
  margin: 0px !important;
}
</style>
