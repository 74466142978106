<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Group_453"
    width="16.694"
    height="20.276"
    data-name="Group 453"
    viewBox="0 0 16.694 20.276"
  >
    <path
      id="Path_128"
      d="M510.769 520.5h7.157v-7.149h4.769L514.351 505 506 513.351h4.769zm0 0"
      class="cls-1"
      data-name="Path 128"
      transform="translate(-506 -505)"
    />
    <path
      id="Rectangle_550"
      d="M0 0H16.694V2.388H0z"
      class="cls-1"
      data-name="Rectangle 550"
      transform="translate(0 17.888)"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
