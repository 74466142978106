<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
    <g transform="translate(-12 -712)">
      <circle cx="14" cy="14" r="14" fill="#3f454a" transform="translate(12 712)" />
      <path
        fill="#fff"
        d="M10.149 15.958a1 1 0 1 1 1 1.042 1.02 1.02 0 0 1-1-1.042zm-6.478 0a1 1 0 1 1 1 1.042 1.02 1.02 0 0 1-1.001-1.042zm-.182-2.571a1.325 1.325 0 0 1-1.277-1.08L.029 4.843a1.113 1.113 0 0 1 .194-.935 1 1 0 0 1 .793-.395h13.017l.382-1.951A1.859 1.859 0 0 1 16.224 0H17.5a.511.511 0 0 1 .5.521.511.511 0 0 1-.5.521h-1.276a.855.855 0 0 0-.832.72l-2.055 10.521a1.315 1.315 0 0 1-1.281 1.1zm-.313-1.34a.332.332 0 0 0 .313.3h8.568a.31.31 0 0 0 .3-.261l1.471-7.528H1.016z"
        transform="translate(17 718)"
      />
    </g>
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
