<template>
  <v-dialog v-model="dialog" persistent max-width="290">
    <v-card class="overflow-hidden">
      <v-card-title class="pa-0">
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <div class="px-4 py-1 d-flex align-center justify-center">
        <v-img
          src="../assets/image/alert.png"
          contain
          width="30"
          height="30"
          class="mx-2"
          v-if="isShowDanger"
        />
        <span class="titleAlert d-block text-center" v-html="title"></span>
      </div>
      <v-row class="mx-0 px-10 my-0" v-if="isOne">
        <v-col :cols="12" class="px-12">
          <v-btn small block tile @click="confirm" color="primary3" dark>
            ตกลง
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mx-0" v-else>
        <v-col :cols="6">
          <v-btn small block tile @click="hide" color="primary2" dark>
            ยกเลิก
          </v-btn>
        </v-col>
        <v-col :cols="6">
          <v-btn small block tile @click="confirm" color="primary3" dark>
            ตกลง
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import Modal from '../plugins/modal'
export default {
  name: 'AlertModal',
  data: () => {
    return {
      isOne: false,
      dialog: false,
      isShowDanger: false,
      title: '',
    }
  },
  methods: {
    hide() {
      this.dialog = false
    },
    close() {
      if (typeof this.onClose === 'function') {
        this.onClose()
        this.hide()
      } else {
        this.hide()
      }
    },
    confirm() {
      if (typeof this.onConfirm === 'function') {
        this.onConfirm()
        this.hide()
      } else {
        this.hide()
      }
    },
    show(params) {
      this.dialog = true
      this.title = params.title
      this.onConfirm = params.onConfirm
      this.onClose = params.onClose
      this.isOne = params.isOne
      this.isShowDanger = params?.isShowDanger
    },
  },
  beforeMount() {
    Modal.EventBus.$on('show', params => {
      this.show(params)
    })
  },
}
</script>

<style lang="scss" scoped>
.titleAlert {
  font-size: 1.1rem;
  color: #6f5f5e;
}
.v-btn.v-size--small {
  font-size: 0.85rem;
}
</style>
