<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37">
    <g transform="translate(.646 .87)">
      <circle
        cx="18"
        cy="18"
        r="18"
        fill="none"
        stroke="#fff"
        stroke-miterlimit="10"
        transform="translate(-.146 -.37)"
      />
      <path
        fill="#fff"
        d="M9.607 4.508H5.69V.591a.591.591 0 0 0-1.182 0v3.917H.591a.591.591 0 1 0 0 1.182h3.917v3.917a.591.591 0 0 0 1.182 0V5.69h3.917a.591.591 0 1 0 0-1.182"
        transform="translate(12.591 12.68)"
      />
    </g>
  </svg>
</template>

<script>
export default {}
</script>
<style lang="scss" scoped></style>
