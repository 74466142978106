import Vue from 'vue'
import VueRouter from 'vue-router'
import Topbar from '../components/Topbar.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/register',
    components: {
      topbar: Topbar,
      default: () => import('../views/RegisterEmployee.vue'),
    },
    meta: {
      ishideBack: true,
      toolbartitle: 'สมัครสมาชิกพนักงาน',
    },
  },
  {
    path: '/listshop',
    components: {
      topbar: Topbar,
      default: () => import('../views/ListShop.vue'),
    },
    meta: {
      ishideBack: true,
      toolbartitle: 'จัดการร้านค้า',
      isSearch: true,
    },
  },
  {
    path: '/shop',
    components: {
      topbar: Topbar,
      default: () => import('../views/Shop.vue'),
    },
    meta: {
      toolbartitle: 'ลงทะเบียนร้านค้า',
    },
  },
  {
    path: '/editshop',
    components: {
      topbar: Topbar,
      default: () => import('../views/EditShop.vue'),
    },
    meta: {
      toolbartitle: 'แก้ไขข้อมูลร้านค้า',
    },
  },
  {
    path: '/product',
    components: {
      topbar: Topbar,
      default: () => import('../views/Product.vue'),
    },
    meta: {
      toolbartitle: null,
    },
  },
  {
    path: '/productdetail',
    name: 'ProductDetail',
    components: {
      topbar: Topbar,
      default: () => import('../views/ProductDetail.vue'),
    },
    meta: {
      toolbartitle: null,
    },
  },
  {
    path: '/productlist',
    components: {
      topbar: Topbar,
      default: () => import('../views/ProductList.vue'),
    },
    meta: {
      toolbartitle: '',
    },
  },
  {
    path: '/cart',
    components: {
      topbar: Topbar,
      default: () => import('../views/Cart.vue'),
    },
    meta: {
      ishideBack: true,
      toolbartitle: 'ตะกร้าสินค้า',
    },
  },
  {
    path: '/confirmcart',
    components: {
      topbar: Topbar,
      default: () => import('../views/ConfirmCart.vue'),
    },
    meta: {
      toolbartitle: 'สรุปใบสั่งซื้อ',
    },
  },
  {
    path: '/ordercompleted',
    components: {
      topbar: Topbar,
      default: () => import('../views/OrderCompleted.vue'),
    },
    meta: {
      ishideBack: true,
      toolbartitle: 'สั่งสินค้าสำเร็จ',
    },
  },
  {
    path: '/orderstatus',
    components: {
      topbar: Topbar,
      default: () => import('../views/OrderStatus.vue'),
    },
    meta: {
      ishideBack: true,
      toolbartitle: 'สถานะใบสั่งซื้อ',
    },
  },
  {
    path: '/uploadpayment',
    components: {
      topbar: Topbar,
      default: () => import('../views/UploadPayment.vue'),
    },
    meta: {
      toolbartitle: 'แจ้งการชำระเงิน',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
