/*eslint-disable */
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
Vue.use(Vuetify)
import Edit from '../assets/icon/Edit.vue'
import Plus from '../assets/icon/Plus.vue'
import Cart from '../assets/icon/Cart.vue'
import Upload from '../assets/icon/Upload.vue'
import PlusShop from '../assets/icon/PlusShop.vue'
export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary2: '#06bebd',
        primary3: '#7192c0',
      },
    },
  },
  icons: {
    values: {
      edit: {
        component: Edit,
      },
      plus: {
        component: Plus,
      },
      cart: {
        component: Cart,
      },
      upload: {
        component: Upload,
      },
      plusShop: {
        component: PlusShop,
      },
    },
  },
})
