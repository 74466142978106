<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
    <g transform="translate(.646 .87)">
      <circle
        cx="15"
        cy="15"
        r="15"
        fill="#fff"
        stroke="#b3bdc2"
        stroke-miterlimit="10"
        transform="translate(-.146 -.37)"
      />
      <path
        fill="#b3bdc2"
        d="M7.9 3.709H4.681V.486a.486.486 0 0 0-.972 0v3.223H.486a.486.486 0 1 0 0 .972h3.223V7.9a.486.486 0 0 0 .972 0V4.681H7.9a.486.486 0 1 0 0-.972"
        transform="translate(10.334 10.367)"
      />
    </g>
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
